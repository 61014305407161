/**
 * Created by osirvent on 18/11/2015.
 */

angular
    .module('annexaApp')
    .constant('apiSign', {
        getSignProcessActions: './api/signprocessactions',
        countSignProcessActionsByFilter: './api/signprocessactions/countByfilter',
        getSignActionTypeSelected: './api/signprocessactions/getSignActionTypeSelected',
        getDelegatedDelegations:'./api/delegationUsers/findByDelegatedUserIdAndStartDelegationDateBeforeAndEndDelegationDateAfter',
        getDelegatorDelegations:'./api/delegationUsers/findByDelegatorUserIdAndEndDelegationDateAfter',
        getValidsDocuments:'./api/signprocessactions/validsDocuments',
        insertDelegationUsers: './api/delegationUsers/insertDelegationUser',
        deleteDelegationUsers: './api/delegationUsers/deleteDelegationUser',
        getSignConfigUserById: './api/signConfigUser/findFirstByUserId',
        getSignConfigUser: './api/signConfigUser/insertOrUpdateConfig',
        getUserCertificates: './api/certificates/getUserCertificates',
        getCountDocsToSignWithLocalSignature: './api/sign/getCountDocsToSignWithLocalSignature',
        executeSign: './api/sign/do',
        SIGNSTATE_REJECTED: {code:'R', id:"REJECTED"},
        VALIDATE_CODE: 'VALIDATION',
        SIGNATURE_CODE: 'SIGN',
        SIGNATURE_RECOGNIZED_CODE: 'LOCALSIGN',
        SIGNATURE_CLOUD_ANDORRA_CODE: 'CLOUDSIGN_ANDORRA',
        SIGNATURE_BIO_SIGN_CODE: 'BIOSIGN',
		SIGNATURE_AUTOFIRMA_CODE: 'AUTOFIRMA',
		SIGNATURE_CENTRALIZEDSIGN_CODE: 'CENTRALIZEDSIGN',
		SIGNATURE_THIRDSIGN_CODE: 'THIRDSIGN',
		SIGNATURE_AUTOSIGN_ORGAN_CODE: 'AUTOSIGN_ORGAN'
   })
   .constant('SignModals', {
       sendToSign: {
           title: 'global.literals.signProcess',
           size: 'modal-lg',
           icon: 'fa fa-pencil-square-o',
           data: undefined,
           content: [
               '<annexa-send-to-sign document-ids="modal.data.documentIds" documents="modal.data.documents" result="modal.data.result" dossier-receivers="modal.data.dossierReceivers"></annexa-send-to-sign>'
           ],
           alerts: []
       },
	   signAutoFirma: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           data: undefined,
           content: [
               '<annexa-sign-result documents-to-sign="modal.data.docsToSign" autofirma="modal.data.autofirma"></annexa-sign-result>'
           ],
           alerts: []
       },
       signWizard: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           wizard: {
               modal: undefined,
               finishWizard: function () {
                   if(this.modal) {
                       this.modal.submitModal();
                   }
               },
               exitValidation: function (form) {
                   return form && !form.$invalid;
               },
               steps: [
                   {
                       title: 'global.sign.configModal.conflictModal.title',
                       key: 'conflictSignPosition',
                       disabled: true,
                       disableNext: function (model) {
                           var allSelected = true;

                           _.forOwn(model, function (value, key) {
                               if(value == -1) {
                                   allSelected = false;
                               }
                           });

                           return !allSelected;
                       },
                       exitValidation: function (model) {
                           var allSelected = true;

                           _.forOwn(model, function (value, key) {
                               if(value == -1) {
                                   allSelected = false;
                               }
                           });

                           return allSelected;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'signConflict',
                                   type: 'annexaSignConflict',
                                   templateOptions: {
                                       required: true,
                                       documents: [],
                                       textConflict: 'global.sign.configModal.conflictModal.description'
                                   },
                                   controller: ['$scope', function($scope) {
                                       $scope.radioClick = function($event, document, position) {
                                           if($scope.model && $scope.model[document.document.id]) {
                                               $scope.model[document.document.id] = position;
                                               angular.element('.' + document.document.id + ' i').removeClass('fa-dot-circle-o');
                                               angular.element('.' + document.document.id + ' i').addClass('fa-circle-o');
                                               angular.element($event.target).removeClass('fa-circle-o');
                                               angular.element($event.target).addClass('fa-dot-circle-o');
                                           }
                                       }
                                   }]
                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.queued.signComments',
                       key: 'saveCommentsDocument',
                       disabled: true,
                       disableNext: function (model) {
                           return false;
                       },
                       exitValidation: function (model) {
                           return true;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'saveCommentsToDocument',
                                   type: 'annexaSaveCommentsToDocument',
                                   templateOptions: {
                                       required: true,
                                       documents: [],
                                       textSignComment: 'global.sign.queued.signCommentsDescription'
                                   }
                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.queued.chooseCertificate',
                       key: 'serverCertificate',
                       disabled: true,
                       disableNext: function(model, wizard) {
                           var disabled = true;
                           if(model && model.serverCertificate && model.serverCertificate.id){
                               disabled = false;
                           }
                           return disabled;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'serverCertificate',
                                   type: 'annexaCertificates',
                                   templateOptions: {
                                       required: true,
                                       certificates: []
                                   },
                                   controller: ['SignService', '$rootScope', '$scope', function (SignService, $rootScope, $scope) {
                                       $scope.loading = true;
                                       SignService.getUserCertificates($rootScope.LoggedUser.id)
                                           .then(function(data) {
                                               if(data) {
                                                   var decodedData = JSOG.decode(data);
                                                   _.forEach(decodedData, function (certificate) {
                                                       certificate.isDelegated = function() {
                                                           return this.userOwner.id != $rootScope.LoggedUser.id;
                                                       };
                                                   });

                                                   $scope.to.certificates = decodedData;

                                                   $scope.loading = false;
                                               }
                                           })
                                   }]
                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.literals.pinTitle',
                       key: 'serverCertificatePIN',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'pinServerCertificate',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'password',
                                       label: 'global.literals.PIN',
                                       required: true,
                                       focus: false
                                   }

                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.queued.chooseLocalCertificate',
                       key: 'localCertificate',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'localCertificate',
                                   type: 'annexaCertificates',
                                   templateOptions: {
                                       required: true,
                                       certificates: []
                                   },
                                   controller: ['SignService', '$rootScope', '$scope', function (SignService, $rootScope, $scope) {
                                       $scope.loading = false;
                                   }]
                               }
                           ]
                       }
                   }
               ]
           },
           submitModal: function () {
           },
           alerts: []
       },
       signWizardCloudSignAndorra: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           wizard: {
               modal: undefined,
               finishWizard: function () {
                   if(this.modal) {
                       this.modal.submitModal();
                   }
               },
               exitValidation: function (form) {
                   return form && !form.$invalid;
               },
               steps: [
                   {
                       title: 'global.sign.literals.enterNRT',
                       key: 'enterNRT',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'nrt',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.NRT',
                                       required: true,
                                       focus: false
                                   }
                               },
                               {
                                   key: 'description',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.description',
                                       required: true,
                                       focus: false
                                   }
                               },
	                           	{
	                           		key: 'certType',
	                           		type: 'annexaRadioCheckboxRow',
	                           		className: 'col-sm-12',
	                           		data: {
	                                       informed: true,
	                                       row: true,
	                                       clear: function ($event, model, key, $select) {
	                                           $event.stopPropagation();
	                                           model[key] = undefined;
	                                           if ($select) {
	                                               $select.selected = undefined;
	                                               $select.search = undefined;
	                                           }
	                                       }
	                                   },
	                           		templateOptions: {
	                           			type: 'radio',
	                           			radioClass: 'radio-inline',
	                                       optionsAttr: 'bs-options',
	                                       required: true,
	                                       validate: false,
	                                       options:[],
	                                       ngOptions:'option[to.valueProp] as option in to.options',
	                                       valueProp: 'id',
	                                       labelProp: 'description',
	                                       label: 'global.literals.certType'
	                           		},
	                           		controller: function ($scope, $filter, $rootScope) {
	                           			   var idCiu = 'CIU,FUN,COM,ADV';
	                           			   var idFun = 'FUN,CIU,COM,ADV';
	                           			   var idCom = 'COM,CIU,FUN,ADV';
	                           			   var idAdv = 'ADV,CIU,FUN,COM';
	                                       $scope.to.options = [
	                                           {id:idCiu, description: $filter("translate")('global.literals.cert_ciu')},
	                                           {id:idFun, description: $filter("translate")('global.literals.cert_fun')},
	                                           {id:idCom, description: $filter("translate")('global.literals.cert_com')},
	                                           {id:idAdv, description: $filter("translate")('global.literals.cert_adv')}
	                                       ];	                                       
	                                       if ($rootScope.app.configuration.sign_certificate_default_cloud_andorra && $rootScope.app.configuration.sign_certificate_default_cloud_andorra.value) {
	                                    	   var value = $rootScope.app.configuration.sign_certificate_default_cloud_andorra.value;
	                                    	   switch (value) {
                                    	   	   	   case 'CIU':
                                    	   	   			$scope.model.certType = idCiu;
                                    	   	   			break;	
                                    	   	   	   case 'FUN':
                                    	   	   			$scope.model.certType = idFun;
                                    	   	   			break;		                                    	   
	                                    	   	   case 'COM':
	                                    	   		   $scope.model.certType = idCom;
	                                               break;	
		                                    	   case 'ADV':
		                                    		   $scope.model.certType = idAdv;
		                                               break;
	                                    	   }	                                    	   
	                                       }
	                                   }
	                           	}                             
                           ]
                       }
                   }
               ]
           },
           submitModal: function () {
           },
           alerts: []
       },
       signWizardBIOSIGN: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           wizard: {
               modal: undefined,
               finishWizard: function () {
                   if(this.modal) {
                       this.modal.submitModal();
                   }
               },
               exitValidation: function (form) {
                   return form && !form.$invalid;
               },
               steps: [
            	   {
                           title: 'global.sign.configModal.conflictModal.title',
                           key: 'conflictSignPosition',
                           disabled: true,
                           disableNext: function (model) {
                               var allSelected = true;

                               _.forOwn(model, function (value, key) {
                                   if(value == -1) {
                                       allSelected = false;
                                   }
                               });

                               return !allSelected;
                           },
                           exitValidation: function (model) {
                               var allSelected = true;

                               _.forOwn(model, function (value, key) {
                                   if(value == -1) {
                                       allSelected = false;
                                   }
                               });

                               return allSelected;
                           },
                           annexaFormly: {
                               fields: [
                                   {
                                       key: 'signConflict',
                                       type: 'annexaSignConflict',
                                       templateOptions: {
                                           required: true,
                                           documents: [],
                                           textConflict: 'global.sign.configModal.conflictModal.description'
                                       },
                                       controller: ['$scope', function($scope) {
                                           $scope.radioClick = function($event, document, position) {
                                               if($scope.model && $scope.model[document.document.id]) {
                                                   $scope.model[document.document.id] = position;
                                                   angular.element('.' + document.document.id + ' i').removeClass('fa-dot-circle-o');
                                                   angular.element('.' + document.document.id + ' i').addClass('fa-circle-o');
                                                   angular.element($event.target).removeClass('fa-circle-o');
                                                   angular.element($event.target).addClass('fa-dot-circle-o');
                                               }
                                           }
                                       }]
                                   }
                               ]
                           }
                   },
                   {
                       title: 'global.sign.literals.enterBioSignProperties',
                       key: 'enterNRT',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'diviceSign',
                                   type: 'annexaSelectRow',
                                   className: '',
                                   templateOptions: {
                                       optionsAttr: 'bs-options',
                                       ngOptions: 'option in to.options | filter: $select.search',
                                       label: 'global.literals.diviceSign',
                                       valueProp: 'DeviceName',
                                       labelProp: 'DeviceDescription',
                                       placeholder: '',
                                       options: [],
                                       required: true
                                   },
                                   controller: ['$scope', '$filter', 'Language', '$rootScope', 'SignService', function($scope, $filter, Language, $rootScope, SignService) {
                                	   $scope.to.options = [];
                                	   if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.bioSignConfiguration && $rootScope.app.configuration.bioSignConfiguration.baseUrl && $rootScope.app.configuration.bioSignConfiguration.auth){
                                		   SignService.getBioSignDevices($rootScope.app.configuration.bioSignConfiguration.baseUrl, $rootScope.app.configuration.bioSignConfiguration.auth).then(function(data){
                                			   if(data){
                                				   Array.prototype.push.apply($scope.to.options,data);
                                			   }
                                		   }).catch(function(error){
                                			   console.log(error);
                                		   });
                                	   }
                                   }],
                                   data: {
                                       row: true,
                                       informed: true,
                                       colClass: ' col-sm-12',
                                       labelClass: 'label-strong'
                                   }
                               },
                               {
                                   key: 'nameSignedThird',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.nameSignedThird',
                                       required: true,
                                       focus: false
                                   }
                               },
                               {
                                   key: 'docSignedThird',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.docSignedThird',
                                       required: true,
                                       focus: false
                                   }
                               }                               
                           ]
                       }
                   }
               ]
           },
           submitModal: function () {
           },
           alerts: []
       },
       signWizardCENTRALIZED: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           wizard: {
               modal: undefined,
               finishWizard: function () {
                   if(this.modal) {
                       this.modal.submitModal();
                   }
               },
               exitValidation: function (form) {
                   return form && !form.$invalid;
               },
               steps: [
            	   {
                           title: 'global.sign.configModal.conflictModal.title',
                           key: 'conflictSignPosition',
                           disabled: true,
                           disableNext: function (model) {
                               var allSelected = true;

                               _.forOwn(model, function (value, key) {
                                   if(value == -1) {
                                       allSelected = false;
                                   }
                               });

                               return !allSelected;
                           },
                           exitValidation: function (model) {
                               var allSelected = true;

                               _.forOwn(model, function (value, key) {
                                   if(value == -1) {
                                       allSelected = false;
                                   }
                               });

                               return allSelected;
                           },
                           annexaFormly: {
                               fields: [
                                   {
                                       key: 'signConflict',
                                       type: 'annexaSignConflict',
                                       templateOptions: {
                                           required: true,
                                           documents: [],
                                           textConflict: 'global.sign.configModal.conflictModal.description'
                                       },
                                       controller: ['$scope', function($scope) {
                                           $scope.radioClick = function($event, document, position) {
                                               if($scope.model && $scope.model[document.document.id]) {
                                                   $scope.model[document.document.id] = position;
                                                   angular.element('.' + document.document.id + ' i').removeClass('fa-dot-circle-o');
                                                   angular.element('.' + document.document.id + ' i').addClass('fa-circle-o');
                                                   angular.element($event.target).removeClass('fa-circle-o');
                                                   angular.element($event.target).addClass('fa-dot-circle-o');
                                               }
                                           }
                                       }]
                                   }
                               ]
                           }
                   },
                   {
                       title: 'global.sign.literals.enterCentralizedSignProperties',
                       key: 'enterNRT',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'nameSignedThird',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.nameSignedThird',
                                       required: true,
                                       focus: false
                                   }
                               },
                               {
                                   key: 'docSignedThird',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.literals.docSignedThird',
                                       required: true,
                                       focus: false
                                   }
                               }                               
                           ]
                       }
                   }
               ]
           },
           submitModal: function () {
           },
           alerts: []
       },
       seeProcess: {
           title: 'global.literals.signProcess',
           size: 'modal-lg',
           icon: 'fa fa-pencil-square-o',
           data: undefined,
           content: [
		    '<div class="col-sm-4">'+
	        '    <annexa-label-value type="text" label="global.literals.sentBy" value="modal.data.signatureCircuitDocument.userSendedToSign.name + \' \' + modal.data.signatureCircuitDocument.userSendedToSign.surename1 + (modal.data.signatureCircuitDocument.userSendedToSign.surename2 ? \' \' + modal.data.signatureCircuitDocument.userSendedToSign.surename2 : \'\')" />'+
	        '</div>'+
	        '<div class="col-sm-4">'+
	        '    <annexa-label-value type="datetime" label="global.sec.literals.sendDate" value="modal.data.signatureCircuitDocument.startDate" row-class="b-b m-b-sm" />'+
	        '</div>'+
	        '<div class="col-sm-4" ng-if="modal.data.signatureCircuitDocument.signState == \'FINALLY\'">'+
	        '	<annexa-label-value type="other" label="global.literals.state" value="\'global.signState.\' + modal.data.signatureCircuitDocument.signState | translate" value-class="labelEstat label label-breakable success" />'+
	        '</div>'+
	        '<div class="col-sm-4" ng-if="modal.data.signatureCircuitDocument.signState == \'REJECTED\'">'+
	        '	<annexa-label-value type="other" label="global.literals.state" value="\'global.signState.\' + modal.data.signatureCircuitDocument.signState | translate" value-class="labelEstat label label-breakable warning" />'+
	        '</div>'+
	        '<div class="col-sm-4" ng-if="modal.data.signatureCircuitDocument.signState != \'FINALLY\' && modal.data.signatureCircuitDocument.signState != \'REJECTED\'">'+
	        '	<annexa-label-value type="other" label="global.literals.state" value="\'global.signState.\' + modal.data.signatureCircuitDocument.signState | translate" value-class="labelEstat label label-breakable" />'+
	        '</div>'+
	        '<div class="col-sm-12">'+
	        '    <annexa-label-value type="text" label="global.literals.observations" value="modal.data.signatureCircuitDocument.comments" />'+
	        '</div>'+
	        '<div class="col-sm-12" ng-if="modal.data.signatureCircuitDocument.urgent">'+
	        '    <annexa-label-value type="text" label="global.literals.urgent" value="modal.data.signatureCircuitDocument.urgentReason" />'+
	        '</div>'+
            '<table class="table m-a-0 table-striped">'+
            '    <thead>'+
            '        <tr>'+
            '            <th style="width:5%"><span class="text-muted" translate="global.literals.order">Order</span></th>'+
            '            <th style="width:10%"><span class="text-muted" translate="global.literals.action">Type</span></th>'+
            '            <th style="width:15%"><span class="text-muted" translate="global.literals.responsible">User</span></th>'+
            '            <th style="width:20%"><span class="text-muted" translate="global.literals.state">Status</span></th>'+
            '            <th style="width:20%"><span class="text-muted" translate="global.literals.datetime">Date / Time</span></th>'+
            '            <th style="width:30%"><span class="text-muted" translate="global.literals.comments">Comments</span></th>'+
            '        </tr>'+
            '    </thead>'+
            '    <tbody>'+
            '        <tr ng-repeat="action in modal.data.signatureCircuitDocument.signProcessActions  | orderBy : \'actionOrder\' ">'+
            '            <td class="v-middle">{{ action.actionOrder}}</td>'+
            '            <td class="v-middle">{{ action.signActionType[modal.data.languageColumn]}}</td>'+
            '            <td class="v-middle" ng-if="(!action.userSign || action.userMustSign.id == action.userSign.id) && action.signActionType.code != \'THIRDSIGN\' && action.signActionType.code != \'AUTOSIGN_ORGAN\'">'+
            '            	{{ action.userMustSign.name }} {{action.userMustSign.surename1 || \'\'}} {{action.userMustSign.surename2 || \'\'}}'+
            '            </td>'+
            '            <td class="v-middle" ng-if="action.userSign && action.userMustSign.id != action.userSign.id && action.signActionType.code != \'THIRDSIGN\' && action.signActionType.code != \'AUTOSIGN_ORGAN\'">'+
            '            	{{ action.userSign.name }} {{ action.userSign.surename1 || \'\' }} {{ action.userSign.surename2 || \'\' }} {{\'global.sign.literals.byDelegationOf\' | translate}} {{ action.userMustSign.name }} {{action.userMustSign.surename1 || \'\'}} {{action.userMustSign.surename2 || \'\'}}'+
            '            </td>'+
            '            <td class="v-middle" ng-if="action.signActionType.code == \'AUTOSIGN_ORGAN\'">'+
            '            	{{ action.userSign.name }} {{ action.userSign.surename1 || \'\' }} {{ action.userSign.surename2 || \'\' }}'+
            '            </td>'+
            '            <td class="v-middle" ng-if="action.signActionType.code == \'THIRDSIGN\'">'+
            '            	{{ action.third.name }} {{ action.third.surname1Particles || \'\' }} {{ action.third.surename1 || \'\' }} {{ action.third.surname2Particles || \'\' }} {{ action.third.surename2 || \'\' }} ({{action.thirdAddress.telematicValue}})'+
            '            </td>'+
            '            <td class="v-middle"><span class="d-block label {{action.processActionState.style}}" >{{ \'global.signState.\'+action.signState | annexatranslate: app.language}}</span></td>'+
            '            <td class="v-middle">{{ action.endDate | date:\'dd/MM/yyyy HH:mm\' }}</td>'+
            '            <td class="v-middle">{{ action.additionalData }}<br ng-if="action.additionalData"/>{{ action.comments}}</td>'+
            '        </tr>'+
            '    </tbody>'+
            '</table>'
           ],
           alerts: []
       },
       createDiligenceStampWizard: {
           title: 'global.sign.literals.signWizard',
           size: '',
           icon: 'fa fa-pencil-square-o',
           wizard: {
               modal: undefined,
               finishWizard: function () {
                   if(this.modal) {
                       this.modal.submitModal();
                   }
               },
               exitValidation: function (form) {
                   return form && !form.$invalid;
               },
               steps: [
                   {
                       title: 'global.diligence_stamp_type.title',
                       key: 'diligenceStampType',
                       disabled: false,
                       disableNext: function (model, wizard) {
                    	   if(model && model.type && model.type.certificateType != 'ORGAN'){
                    		   wizard.steps[1].disabled = false;
                        	   wizard.steps[2].disabled = false;
	                    	   if(model && model.type && model.type.canChange && !model.subject){
	                     		  return true;
	                     	   }else if(model && model.type){
	                     		  return false;
	                     	   }
                    	   }else{
                               wizard.steps[1].disabled = true;
                        	   wizard.steps[2].disabled = true;
                    	   }
                      	   return true;
                       },
                       exitValidation: function (model) {
                    	   if(model && model.type){
                    	      if(model.type.canChange && !model.subject){
                          		  return false;
                          	  }else{
                          		  return true;
                          	  }
                            }
                            return false;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'type',
                                   type: 'annexaSelectRow',
                                   className: 'col-xs-12',
                                   templateOptions: {
                                       optionsAttr: 'bs-options',
                                       ngOptions: 'option in to.options | filter: $select.search',
                                       label: 'global.diligence_stamp_type.diligenceType',
                                       valueProp: 'id',
                                       labelProp: 'language1',
                                       placeholder: '',
                                       options: [],
                                       required: true,
                                       onSelected: function($item) {
                                           var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                           $rootScope.$broadcast('modifyDiligenceStampType', { item: $item });
                                       }
                                   },
                                   data: {
                                       row: true,
                                       informed: true,
                                       colClass: ' col-xs-12',
                                       labelClass: 'label-strong'
                                   },
                                   controller:['$scope', 'Language', 'GlobalDataFactory', '$rootScope', function($scope, Language, GlobalDataFactory, $rootScope) {
                                	   $scope.to.labelProp = Language.getActiveColumn();
                                	   var diligencesStampTypes = [];
                                	   if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles && $rootScope.LoggedUser.userProfiles.length > 0 && GlobalDataFactory.diligenceStampTypes && GlobalDataFactory.diligenceStampTypes.length > 0){
                                		   _.forEach(GlobalDataFactory.diligenceStampTypes, function(type){
                   		                		if(type.profiles && type.profiles.length > 0){
                   		                			var profiles = $linq(type.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                                           if(y.profile){
                                                               return (x.profile.id == y.profile.id);
                                                           }
                                                           else{
                                                               return false;
                                                           }
                                                       }).toArray();
                                                       if(profiles && profiles.length > 0){
                                                       		diligencesStampTypes.push(angular.copy(type));
                                                       }
                   		                		}
                   		                	});
                   		               }
                   		               $scope.to.options = diligencesStampTypes;
                                   }]
                               },
                               {
                                   key: 'userPosition',
                                   type: 'annexaSelectRow',
                                   className: 'col-sm-12',
                                   templateOptions: {
                                       required: true,
                                       focus: false,
                                       label: 'global.diligence_stamp_type.userPosition',
                                       optionsAttr: 'bs-options',
                                       ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                       valueProp: 'id',
                                       labelProp: 'language1',
                                       placeholder: '',
                                       options: []
                                   },
                                   hideExpression: function($viewValue, $modelValue, scope) {
                                   	var hide = true;
                                   	if (scope.model && scope.model.type && scope.model.type.certificateType == 'USER' && scope.$root && scope.$root.LoggedUser && scope.$root.LoggedUser.userPositions) {
                                   		hide = false;
                                   	}
                                   	return hide;
                                   },
                                   controller: ['$scope', '$rootScope', 'Language', function($scope, $rootScope, Language) {
                                       $scope.to.labelProp = Language.getActiveColumn();
                                       $scope.to.options = (($rootScope.LoggedUser && $rootScope.LoggedUser.userPositions)?$linq($rootScope.LoggedUser.userPositions).select("x => x.position").toArray():[]);
                                       
                                   }],
                                   data: {
                                       row: true,
                                       informed: true,
                                       colClass: ' col-sm-12',
                                       labelClass: 'label-strong'
                                   }
                               },
                               {
                                   key: 'subject',
                                   type: 'annexaTextAreaRow',
                                   className: 'col-sm-12',
                                   validation: {
                                       show: true
                                   },
                                   expressionProperties: {
                                       'validation.show': 'formControl.$submitted'
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.diligence_stamp_type.text',
                                       rows: 5,
                                       required: true
                                   },
                                   watcher:{
                                       type: '$watch',
                                       expression: 'model',
                                       listener: function(field, _new) {
                                           if(field.formControl){
                                               field.formControl.$validate();
                                           }
                                       }
                                   },
                                   hideExpression: function ($viewValue, $modelValue, scope) {
                                       if (scope.model && scope.model.type && scope.model.type.canChange) {
                                    	   return false;
                                       }else{
                                    	   scope.model.subject = ''
                                    	   return true;
                                       }
                                   },
                                   controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                	   if($scope.model && $scope.model.type){
                                		   $scope.model.subject = (($scope.model.type && $scope.model.type['diligenceTextL'+Language.getActiveColumn().substring(1)])?$scope.model.type['diligenceTextL'+Language.getActiveColumn().substring(1)]:'');
                                	   }
                                	   $scope.$on('modifyDiligenceStampType', function(event, args){
                                    	   if(args && args.item){
                                    		   var subjectColumn = 'diligenceTextL'+Language.getActiveColumn().substring(1);
                                    		   $scope.model.subject = args.item[subjectColumn];
                                    	   }
                                       });
                                   }]
                               },
                               {
                                   key: 'subjectAux',
                                   type: 'annexaTextAreaRow',
                                   className: 'col-sm-12',
                                   validation: {
                                       show: true
                                   },
                                   expressionProperties: {
                                       'validation.show': 'formControl.$submitted'
                                   },
                                   templateOptions: {
                                       type: 'text',
                                       label: 'global.diligence_stamp_type.text',
                                       rows: 5,
                                       required: true,
                                       disabled: true
                                   },
                                   hideExpression: function ($viewValue, $modelValue, scope) {
                                       if (scope.model && scope.model.type && scope.model.type.canChange) {
                                    	   return true;
                                       }else{
                                    	   return false;
                                       }
                                   },
                                   controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                                	   if($scope.model && $scope.model.type){
                                		   $scope.model.subjectAux = (($scope.model.type && $scope.model.type['diligenceTextL'+Language.getActiveColumn().substring(1)])?$scope.model.type['diligenceTextL'+Language.getActiveColumn().substring(1)]:'');
                                	   }
                                	   $scope.$on('modifyDiligenceStampType', function(event, args){
                                    	   if(args && args.item){
                                    		   var subjectColumn = 'diligenceTextL'+Language.getActiveColumn().substring(1);
                                    		   $scope.model.subjectAux = args.item[subjectColumn];
                                    	   }
                                       });
                                   }]
                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.queued.chooseCertificate',
                       key: 'serverCertificate',
                       disabled: true,
                       disableNext: function(model, wizard) {
                           var disabled = true;
                           if(model && model.serverCertificate && model.serverCertificate.id){
                               disabled = false;
                           }
                           return disabled;
                       },
                       exitValidation: function(model) {
                           var selected = false;
                           if(model.serverCertificate && model.serverCertificate.id){
                               selected = true;
                           }
                           return selected;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'serverCertificate',
                                   type: 'annexaCertificates',
                                   templateOptions: {
                                       required: true,
                                       certificates: []
                                   },
                                   controller: ['SignService', '$rootScope', '$scope', function (SignService, $rootScope, $scope) {
                                       $scope.loading = true;
                                       SignService.getUserCertificates($rootScope.LoggedUser.id)
                                           .then(function(data) {
                                               if(data) {
                                                   var decodedData = JSOG.decode(data);
                                                   _.forEach(decodedData, function (certificate) {
                                                       certificate.isDelegated = function() {
                                                           return this.userOwner.id != $rootScope.LoggedUser.id;
                                                       };
                                                   });

                                                   $scope.to.certificates = decodedData;

                                                   $scope.loading = false;
                                               }
                                           })
                                   }]
                               }
                           ]
                       }
                   },
                   {
                       title: 'global.sign.literals.pinTitle',
                       key: 'serverCertificatePIN',
                       disabled: true,
                       disableNext: function () {
                           return this.annexaFormly.form.$invalid;
                       },
                       exitValidation: function(model) {
                           var selected = false;
                           if(model.pinServerCertificate){
                               selected = true;
                           }
                           return selected;
                       },
                       annexaFormly: {
                           fields: [
                               {
                                   key: 'pinServerCertificate',
                                   type: 'annexaInputRow',
                                   className: 'col-sm-12',
                                   data: {
                                       row: true,
                                       colClass: '',
                                       labelClass: 'label-strong',
                                       informed: true,
                                       clear: function($event,model,key, $select) {
                                           $event.stopPropagation();
                                           model[key] = undefined;
                                           if($select) {
                                               $select.selected = undefined;
                                               $select.search = undefined;
                                           }
                                       }
                                   },
                                   templateOptions: {
                                       type: 'password',
                                       label: 'global.literals.PIN',
                                       required: true,
                                       focus: false
                                   }

                               }
                           ]
                       }
                   }
               ]
           },
           submitModal: function () {
           },
           alerts: []
       }, addCustomCircuit: {
           title: 'global.literals.addAction',
           size: 'modal-lg',
           annexaFormly: {
               fields: [
                   {
                       key: 'modal_body',
                       className: 'modal-body p-lg',
                       fieldGroup: [
                            {
                                key: 'actionOrder',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    type: 'number',
                                    label: 'global.literals.order',
                                    minNumber: 1
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model.isConditionedAction) {
                                		return true;
                                	}
                             	   	if(scope.model.chkSignatoryType) {
                             	   		return false;
                             	   	}
                             	   	return true;
                                }
                            },
                            {
                                key: 'paralelConditionedAction',
                                type: 'annexaCheckbox',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.literals.sendToSignWithoutOrder',
                                    validate: true,
                                    required: false
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                	if(scope.model.isConditionedAction) {
                                		return true;
                                	}
                                	return false;
                                }
                            },
                            {
                               key: 'signActionType',
                               type: 'annexaSelectRow',
                               className: 'col-sm-12',
                               data: {
                                   informed:true,
                                   row:true,
                                   clear: function($event,model,key, $select) {
                                       $event.stopPropagation();
                                       model[key] = undefined;
                                       if($select) {
                                           $select.selected = undefined;
                                           $select.search = undefined;
                                       }
                                   },
                                   colClass: ' col-sm-12'
                               },
                               templateOptions: {
                                   optionsAttr: 'bs-options',
                                   ngOptions: 'option in to.options | filter: $select.search',
                                   label: 'global.documents.modal.sendToSign.signType',
                                   valueProp: 'id',
                                   labelProp: 'label',
                                   placeholder: '',
                                   options: [],
                                   required: true,
                                   focus: false
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   return scope.model.paralelConditionedAction;
                               },
                               controller: ['$scope', 'SignLocalFactory','Language', function($scope, SignLocalFactory, Language) {
                                   $scope.to.options = [];
                                   if($scope.model.signActionType && !$scope.model.signActionType.label) {
                                	   $scope.model.signActionType.label = $scope.model.signActionType[Language.getActiveColumn()];
                                   }
                                   if($scope.model.signActionType && $scope.model.signActionType.code == 'PARALELCONDITIONEDSIGN') {
                                	   $scope.model.signActionType = undefined;
                                   }
                                   SignLocalFactory.getSignActionTypes()
                                   		.then(function(data) {
                                   			_.forEach(data, function (value) {
                                   				var type = value;
												var addType = true;
												if($scope.model.signatureWithoutOrder && !type.signatureWithoutOrder){
													addType = false;
												}
												if(type.code == 'PARALELCONDITIONEDSIGN') {
													addType = false;
												}
												if(addType){
	                                   				type.label = value[Language.getActiveColumn()];
	                                   				$scope.to.options.push(type);
												}
                                   			});
                                   });
                               }]
                           },{
                               key: 'signatoryType',
                               type: 'annexaSelectRow',
                               className: 'col-sm-12',
                               data: {
                                   informed:true,
                                   row:true,
                                   clear: function($event,model,key, $select) {
                                       $event.stopPropagation();
                                       model[key] = undefined;
                                       if($select) {
                                           $select.selected = undefined;
                                           $select.search = undefined;
                                       }
                                   },
                                   colClass: ' col-sm-12'
                               },
                               templateOptions: {
                                   optionsAttr: 'bs-options',
                                   ngOptions: 'option in to.options | filter: $select.search',
                                   label: 'global.literals.type',
                                   valueProp: 'id',
                                   labelProp: 'label',
                                   placeholder: '',
                                   options: [],
                                   required: true,
                                   focus: false
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.chkSignatoryType && scope.model.signActionType && scope.model.signActionType.code) {
                                	   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   }
                            		   return scope.model.signActionType.code == 'THIRDSIGN' || scope.model.signActionType.code == 'AUTOSIGN_ORGAN';
                            	   }
                            	   return true;
                               }
                           },
                           {
                               key: 'actionLevel',
                               type: 'annexaInputRow',
                               className: 'col-sm-12',
                               templateOptions: {
                                   required: true,
                                   type: 'number',
                                   label: 'global.documents.modal.sendToSign.level',
                                   minNumber: 1
                               },
                               data: {
                                   row: true,
                                   informed: true,
                                   colClass: ' col-sm-12',
                                   labelClass: 'label-strong'
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.signActionType && scope.model.signActionType.code) {
                                	   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   } else if(scope.model.signActionType.code == 'THIRDSIGN' || scope.model.signActionType.code == 'AUTOSIGN_ORGAN') {
                            			   return true;
                            		   } else if(scope.model.chkSignatoryType && scope.model.signatoryType && scope.model.signatoryType.id) {
                            			   var $rootScope = angular.element(document.body).injector().get('$rootScope');
                            			   return scope.model.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value;
                            		   }
                            	   }
                            	   return true;
                               }
                           },
                           {
                               key: 'user',
                               type: 'annexaLoadUserRow',
                               className: 'col-sm-12',
                               templateOptions: {
                                   type: 'text',
                                   label: 'global.documents.modal.sendToSign.user',
                                   required: true,
                                   focus: false,
                                   options: [],
                                   loadFunction: function () {}
                               },
                               controller: function ($scope, $rootScope, RestService) {
                                   $scope.options.templateOptions.loadFunction = function(value) {
                                       if(!value && !value.val) {
                                           return [];
                                       }

                                       if(value.val != '*' && value.val.length < 3) {
                                           return [];
                                       }

                                       if(value.val == '*') {
                                           value.val = '';
                                       }

                                       return RestService.loadData('common', 'User', value.val)
                                           .then(function(data) {
                                               var users = [];
                                               if(data.data && data.data.content && data.data.content.length > 0) {
                                                   _.forEach(JSOG.decode(data.data.content), function(val) {
                                                       users.push({ 'id':  val.id, 'user': val, 'value': val.completeName });
                                                   });
                                               }
                                               return users;
                                           }).catch(function() {
                                               return [];
                                           });

                                   }
                               },
                               validators:{
                                   "requiredCouncillor": {
                                       "expression": function (viewValue, modelValue, scope) {
                                           var valid = false;
                                           if(modelValue && modelValue.id){
                                               valid = true;
                                           }
                                           return valid;
                                       }
                                   }
                               },
                               watcher:{
                                   type: '$watchCollection',
                                   expression: 'model',
                                   listener: function(field, _new) {
                                       if(field.formControl){
                                           field.formControl.$validate();
                                       }
                                   }
                               },
                               data: {
                                   row: true,
                                   colClass: ' col-sm-12',
                                   labelClass: 'label-strong'
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.signActionType && scope.model.signActionType.code) {
                                	   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   } else if(scope.model.signActionType.code == 'THIRDSIGN' || scope.model.signActionType.code == 'AUTOSIGN_ORGAN') {
                            			   return true;
                            		   } else if(scope.model.chkSignatoryType && scope.model.signatoryType && scope.model.signatoryType.id) {
                            			   var $rootScope = angular.element(document.body).injector().get('$rootScope');
                            			   return scope.model.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value;
                            		   } else {
                            			   return false;
                            		   }
                            	   }
                            	   return true;
                               }
                           },
                           {
                        	   key: 'receiver',
                        	   type: 'annexaRadioCheckboxRow',
                        	   className: 'col-sm-12',
                        	   defaultValue: 'others',
                        	   data: {
                        		   informed: true,
                        		   row: true,
                        		   colClass: ' col-sm-12',
                        		   labelClass: 'label-strong',
                        		   clear: function ($event, model, key, $select) {
                        			   $event.stopPropagation();
                        			   model[key] = undefined;
                        			   if ($select) {
                        				   $select.selected = undefined;
                        				   $select.search = undefined;
                        			   }
                        		   }
                        	   },
	                           templateOptions: {
	                        	   type: 'radio',
	                        	   radioClass: 'radio-inline',
	                               optionsAttr: 'bs-options',
	                               required: true,
	                               validate: false,
	                               options:[],
	                               ngOptions:'option[to.valueProp] as option in to.options',
	                               valueProp: 'id',
	                               labelProp: 'description',
	                               label: 'global.literals.receivers'
	                       		},
	                            hideExpression: function ($viewValue, $modelValue, scope) {
	                            	if(scope.model.paralelConditionedAction) {
                             		   return true;
                             	   } else if(scope.model.signActionType && scope.model.signActionType.code) {
	                         		   return scope.model.signActionType.code != 'THIRDSIGN';
	                         	   }
	                         	   return true;
	                            },
	                       		controller: function ($scope, $filter) {
                            	   $scope.to.options = [
                                       {id:'related', description: $filter("translate")('global.literals.related')},
                                       {id:'others', description: $filter("translate")('global.literals.others')}
                                   ];
	                       		}
                           },
                           {
                               key: 'selectReceiver',
                               type: 'annexaRadioCheckboxRow',
                               className: 'col-sm-12',
                               templateOptions: {
                                   type: 'radioHTMLReceiver',
                                   optionsAttr: 'bs-options',
                                   ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                   label: '',
                                   valueProp: 'value',
                                   labelProp: 'label',
                                   labelHtml: 'labelHtml',
                                   emailHtml: 'emailHtml',
                                   placeholder: '',
                                   options: [],
                                   required: false
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.signActionType && scope.model.signActionType.code) {
                            		   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   } else if (scope.model.signActionType.code == 'THIRDSIGN') {
                            			   return scope.model.receiver != 'related';
                            		   }
                            		   return scope.model.signActionType.code != 'THIRDSIGN';
                            	   }
                            	   return true;
                               },
                               data: {
                                   row: true,
                                   informed: true,
                                   colClass: ' col-sm-12',
                                   labelClass: 'label-strong'
                               }
                           },
                           {
                               key: 'third',
                               type: 'annexaLoadUserRow',
                               className: 'col-sm-12',
                               templateOptions: {
                                   type: 'text',
                                   label: 'global.documents.modal.sendToSign.third',
                                   required: true,
                                   focus: false,
                                   options: [],
                                   loadFunction: function () {},
                                   onSelect: function($item, $model, $label, $event) {
                                       var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                       $rootScope.$broadcast('modifyThirdEvent', { item: $item });
                                   }
                               },
                               controller: function ($scope, $rootScope, RestService) {
                                   $scope.options.templateOptions.loadFunction = function(value) {
                                       if(!value && !value.val) {
                                           return [];
                                       }

                                       if(value.val != '*' && value.val.length < 3) {
                                           return [];
                                       }

                                       if(value.val == '*') {
                                           value.val = '';
                                       }
                                       return RestService.loadData('thirds', 'Third', value.val)
                                           .then(function(data) {
                                               var thirds = [];
                                               if(data.data && data.data.content && data.data.content.length > 0) {
                                                   _.forEach(JSOG.decode(data.data.content), function(val) {
                                                	   var name = '';
                                                       if(val.corporateName) {
                                                           name = val.corporateName;
                                                       } else {
                                                           name = val.name + ((val.surname1Particles) ? ' '+ val.surname1Particles : '') + ' ' + val.surename1;
                                                       }

                                                       if(val.surename2) {
                                                           name += ((val.surname2Particles) ? ' '+ val.surname2Particles : '') + ' ' + val.surename2;
                                                       }

                                                       thirds.push({ 'id':  val.id, 'third': val, 'value': name });
                                                   });
                                               }
                                               return thirds;
                                           }).catch(function() {
                                               return [];
                                           });

                                   }
                               },
                               validators:{
                                   "requiredCouncillor": {
                                       "expression": function (viewValue, modelValue, scope) {
                                           var valid = false;
                                           if(modelValue && modelValue.id){
                                               valid = true;
                                           }
                                           return valid;
                                       }
                                   }
                               },
                               watcher:{
                                   type: '$watchCollection',
                                   expression: 'model',
                                   listener: function(field, _new) {
                                       if(field.formControl){
                                           field.formControl.$validate();
                                       }
                                   }
                               },
                               data: {
                                   row: true,
                                   colClass: ' col-sm-12',
                                   labelClass: 'label-strong'
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.signActionType && scope.model.signActionType.code) {
                            		   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   } else if (scope.model.signActionType.code == 'THIRDSIGN') {
                            			   return scope.model.receiver != 'others';
                            		   }
                            		   return scope.model.signActionType.code != 'THIRDSIGN';
                            	   }
                            	   return true;
                               }
                           },
                           {
                               key: 'thirdAddress',
                               type: 'annexaSelectRow',
                               className: 'col-sm-12',
                               data: {
                                   informed:true,
                                   row:true,
                                   clear: function($event,model,key, $select) {
                                       $event.stopPropagation();
                                       model[key] = undefined;
                                       if($select) {
                                           $select.selected = undefined;
                                           $select.search = undefined;
                                       }
                                   },
                                   colClass: ' col-sm-12'
                               },
                               templateOptions: {
                                   optionsAttr: 'bs-options',
                                   ngOptions: 'option in to.options | filter: $select.search',
                                   label: 'global.documents.modal.sendToSign.telematicMail',
                                   valueProp: 'id',
                                   labelProp: 'telematicValue',
                                   placeholder: '',
                                   options: [],
                                   required: true,
                                   focus: false
                               },
                               controller: ['$scope', '$rootScope', 'SignLocalFactory', function($scope, $rootScope, SignLocalFactory) {
                                   $scope.to.options = [];
                                   if($scope.model.thirdAddress && $scope.model.third && $scope.model.third.third) {
                                	   _.forEach($scope.model.third.third.addresses, function(address) {
                                    	   if(address.addressType == 'TELEMATIC_EMAIL') {
                                    		   $scope.to.options.push(address);
                                    	   }
                                       });
                                   }
                                   $rootScope.$on('modifyThirdEvent', function(event, args) {
                                	   $scope.to.options = [];
                                	   $scope.model.thirdAddress = undefined;
                                       if(args && args.item && args.item.third) {
                                    	   _.forEach(args.item.third.addresses, function(address) {
                                        	   if(address.addressType == 'TELEMATIC_EMAIL') {
                                        		   $scope.to.options.push(address);
                                        	   }
                                           });
                                       }
                                   });
                               }],
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.signActionType && scope.model.signActionType.code) {
                            		   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   } else if (scope.model.signActionType.code == 'THIRDSIGN') {
                            			   return scope.model.receiver != 'others';
                            		   }
                            		   return scope.model.signActionType.code != 'THIRDSIGN';
                            	   }
                            	   return true;
                               }
                           },{
                               key: 'diligenceStampType',
                               type: 'annexaSelectRow',
                               className: 'col-sm-12',
                               data: {
                                   informed:true,
                                   row:true,
                                   clear: function($event,model,key, $select) {
                                       $event.stopPropagation();
                                       model[key] = undefined;
                                       if($select) {
                                           $select.selected = undefined;
                                           $select.search = undefined;
                                       }
                                   },
                                   colClass: ' col-sm-12'
                               },
                               templateOptions: {
                                   optionsAttr: 'bs-options',
                                   ngOptions: 'option in to.options | filter: $select.search',
                                   label: 'global.diligence_stamp_type.diligenceType',
                                   valueProp: 'id',
                                   labelProp: 'label',
                                   placeholder: '',
                                   options: [],
                                   required: true,
                                   focus: false,
                                   onSelected: function($item) {
                                       var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                       $rootScope.$broadcast('modifyDiligenceStampType', { item: $item });
                                   }
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.signActionType && scope.model.signActionType.code) {
                                	   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   }
                            		   return scope.model.signActionType.code != 'AUTOSIGN_ORGAN';
                            	   }
                            	   return true;
                               }
                           },
                           {
                               key: 'subject',
                               type: 'annexaTextAreaRow',
                               className: 'col-sm-12',
                               validation: {
                                   show: true
                               },
                               data: {
                                   row: true,
                                   informed: true,
                                   colClass: ' col-sm-12',
                                   labelClass: 'label-strong'
                               },
                               expressionProperties: {
                                   'validation.show': 'formControl.$submitted'
                               },
                               templateOptions: {
                                   type: 'text',
                                   label: 'global.diligence_stamp_type.text',
                                   rows: 5,
                                   required: true
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.signActionType && scope.model.signActionType.code && scope.model.signActionType.code == 'AUTOSIGN_ORGAN') {
                                	   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   } else if (scope.model && scope.model.diligenceStampType && scope.model.diligenceStampType.canChange) {
                                    	   return false;
                                       }else{
                                    	   return true;
                                       }
                            	   }
                            	   return true;
                               },
                               controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                            	   $scope.$on('modifyDiligenceStampType', function(event, args){
                                	   if(args && args.item){
                                		   var subjectColumn = 'diligenceTextL'+Language.getActiveColumn().substring(1);
                                		   $scope.model.subject = args.item[subjectColumn];
                                	   }
                                   });
                               }]
                           },{
                               key: 'subjectAux',
                               type: 'annexaTextAreaRow',
                               className: 'col-sm-12',
                               validation: {
                                   show: true
                               },
                               data: {
                                   row: true,
                                   informed: true,
                                   colClass: ' col-sm-12',
                                   labelClass: 'label-strong'
                               },
                               expressionProperties: {
                                   'validation.show': 'formControl.$submitted'
                               },
                               templateOptions: {
                                   type: 'text',
                                   label: 'global.diligence_stamp_type.text',
                                   rows: 5,
                                   required: false,
                                   disabled: true
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.signActionType && scope.model.signActionType.code && scope.model.signActionType.code == 'AUTOSIGN_ORGAN') {
                                	   if(scope.model.paralelConditionedAction) {
                                		   return true;
                                	   } else if (scope.model && scope.model.diligenceStampType && scope.model.diligenceStampType.canChange) {
                                    	   return true;
                                       }else{
                                    	   return false;
                                       }
                            	   }
                            	   return true;
                               },
                               controller:['$scope', 'Language', 'GlobalDataFactory', function($scope, Language, GlobalDataFactory) {
                            	   $scope.$on('modifyDiligenceStampType', function(event, args){
                                	   if(args && args.item){
                                		   var subjectColumn = 'diligenceTextL'+Language.getActiveColumn().substring(1);
                                		   $scope.model.subjectAux = args.item[subjectColumn];
                                	   }
                                   });
                               }]
                           },
                           {
                        	   key: 'conditionedActionList',//key: 'emgdeAC',
                               type: 'annexaComponent',
                               className: 'col-xs-12',
                               templateOptions: {
                                   type: 'annexa-add-conditioned-action'
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   return !scope.model.paralelConditionedAction;
                               },
                               data: {}
                           },
                           {
                               key: 'onlyOneSign',
                               type: 'annexaCheckbox',
                               className: 'col-sm-12',
                               templateOptions: {
                                   type: 'checkbox',
                                   label: 'global.literals.onlyOneSign',
                                   validate: true,
                                   required: false
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   return !scope.model.paralelConditionedAction;
                               }
                           },
                           {
                               key: 'maxDurationDays',
                               type: 'annexaInputRow',
                               className: 'col-sm-12',
                               templateOptions: {
                                   required: true,
                                   type: 'number',
                                   label: 'global.documents.modal.sendToSign.days',
                                   minNumber: 1
                               },
                               data: {
                                   row: true,
                                   informed: true,
                                   colClass: ' col-sm-12',
                                   labelClass: 'label-strong'
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                               		if(scope.model.isConditionedAction) {
                               			return true;
                               		}
                               		return false;
                               }
                           },
                           {
                               key: 'condition',
                               type: 'annexaTextAreaRow',
                               className: 'col-sm-12',
                               validation: {
                                   show: true
                               },
                               data: {
                                   row: true,
                                   informed: true,
                                   colClass: ' col-sm-12',
                                   labelClass: 'label-strong'
                               },
                               expressionProperties: {
                                   'validation.show': 'formControl.$submitted'
                               },
                               templateOptions: {
                                   type: 'text',
                                   label: 'global.literals.condition',
                                   rows: 5,
                                   required: false
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   	if(scope.model.isConditionedAction) {
                              			return false;
                              		}
                              		return true;
                               }
                           },
                           { 
                               key: 'addPreExpression',
                               type: 'annexaLabelButton',
                               className: 'col-sm-4',
                               templateOptions: {
                               	buttonLabel: 'global.literals.expressionEvaluator',
                               	executeFunction: function() {}
                               },
                               hideExpression: function ($viewValue, $modelValue, scope) {
                            	   if(scope.model.isConditionedAction) {
                            		   return false;
                            	   }
                            	   return true;
                              }
                           }
                       ]
                   }
               ]
           },
           alerts: [],
           submitModal: function () {}
       },
       signTypeEdit: {
           title: 'global.documents.modal.sendToSign.editSignType',
           size: '',
           annexaFormly: {
               fields: [
                   {
                       key: 'modal_body',
                       className: '',
                       templateOptions: {},
                       fieldGroup: [
                           {
                               key: 'signActionType',
                               type: 'annexaSelectRow',
                               className: 'col-sm-12',
                               data: {
                                   informed:true,
                                   row:true,
                                   clear: function($event,model,key, $select) {
                                       $event.stopPropagation();
                                       model[key] = undefined;
                                       if($select) {
                                           $select.selected = undefined;
                                           $select.search = undefined;
                                       }
                                   },
                                   colClass: ' col-sm-12'
                               },
                               templateOptions: {
                                   optionsAttr: 'bs-options',
                                   ngOptions: 'option in to.options | filter: $select.search',
                                   label: 'global.documents.modal.sendToSign.signType',
                                   valueProp: 'id',
                                   labelProp: 'label',
                                   placeholder: '',
                                   options: [],
                                   required: true,
                                   focus: false
                               },
                               controller: ['$scope', 'SignLocalFactory','Language', 'apiSign', function($scope, SignLocalFactory, Language, apiSign) {
                                   $scope.to.options = [];
                                   if($scope.model.signActionType && !$scope.model.signActionType.label) {
                                	   $scope.model.signActionType.label = $scope.model.signActionType[Language.getActiveColumn()];
                                   }
                                   SignLocalFactory.getSignActionTypes()
                                   		.then(function(data) {
                                   			_.forEach(data, function (value) {
                                   				var type = value;
												var addType = true;
												if(type.code == apiSign.VALIDATE_CODE || type.code == apiSign.SIGNATURE_THIRDSIGN_CODE || type.code == apiSign.SIGNATURE_AUTOSIGN_ORGAN_CODE){
													addType = false;
												}
												if(addType){
	                                   				type.label = value[Language.getActiveColumn()];
	                                   				$scope.to.options.push(type);
												}
                                   			});
                                   });
                               }]
                           }
                       ],
                       wrapper: 'annexaRow'
                   }
               ]
           },
           submitModal: function () {
           }
       }
       
   });
